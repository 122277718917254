var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar min-w-1400"},[_c('div',{staticClass:"secondBox max-w-1400",class:{'secondBox-borrom':_vm.$route.path === '/question' || _vm.$route.path === '/protocol'}},[_vm._m(0),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"Li1",class:{ 'active-link': _vm.isActive('/') }},[_c('router-link',{staticClass:"nav-link1",attrs:{"to":"/"}},[_c('span',{staticClass:"routeText1"},[_vm._v("Pagina de inicio")])])],1),_c('li',{staticClass:"Li2",class:{ 'active-link': _vm.$route.path === '/about' }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/about"}},[_c('span',{staticClass:"routeText2"},[_vm._v("Sobre nosotros")])])],1),_c('li',{staticClass:"dropdown Li3",class:{
          'active-link':
            _vm.$route.path === '/introduce1' || _vm.$route.path === '/introduce2'|| _vm.$route.path === '/introduce3' || _vm.$route.path === '/introduce4'|| _vm.$route.path === '/introduce5'  
           
        }},[_c('router-link',{staticClass:"nav-link3",attrs:{"to":"/introduce1"}},[_c('span',{staticClass:"dropdown-toggle routeText3"},[_vm._v("Nuestro servicio")])]),_c('ul',{ref:"dropdownMenu",staticClass:"dropdown-menu"},[_c('li',{class:{
              'active-link1': _vm.$route.path === '/introduce1',
            },staticStyle:{"color":"red"}},[_c('router-link',{staticClass:"dropdown-toggle routeText6",attrs:{"to":"/introduce1"}},[_vm._v(" Desarrollar soluciones de software ")])],1),_c('div',{staticClass:"dropdown-item-line"}),_c('li',{class:{
              'active-link1': _vm.$route.path === '/introduce2',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText7",attrs:{"to":"/introduce2"}},[_vm._v(" Inteligencia artificial y aprendizaje automático ")])],1),_c('div',{staticClass:"dropdown-item-line"}),_c('li',{class:{
              'active-link1': _vm.$route.path === '/introduce3',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText8",attrs:{"to":"/introduce3"}},[_vm._v(" Servicios de Asesoría Técnica ")])],1),_c('div',{staticClass:"dropdown-item-line"}),_c('li',{class:{
              'active-link1': _vm.$route.path === '/introduce4',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText9",attrs:{"to":"/introduce4"}},[_vm._v(" La seguridad cibernética ")])],1),_c('div',{staticClass:"dropdown-item-line"}),_c('li',{class:{
              'active-link1': _vm.$route.path === '/introduce5',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText10",attrs:{"to":"/introduce5"}},[_vm._v(" Atención al cliente y servicio ")])],1)])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('span',{staticClass:"title"},[_vm._v("VATIN TECNOLOGIA")])])
}]

export { render, staticRenderFns }